@import '~uikit/src/scss/mixins-theme';

@mixin hook-background-misc() {
    .uk-background-primary {
        background-color: $shop-main;
    }
}

@mixin hook-badge() {
    background: $shop-main;
}

@mixin hook-inverse-badge() {
    background: $global-inverse-color;
    color: $base-body-color;
}

@mixin hook-base-misc() {
    ins {
        text-decoration: underline;
    }
}

@mixin hook-breadcrumb-divider() {
    font-family: FontAwesome;
}

@mixin hook-button() {
    position: relative;
    align-items: center;
    vertical-align: middle;
    // padding-top/-bottom: ((1.4 line-height * 16px) / 2) - 1px border = 7.8px
    // This results in a total button height of 40px (for a single line label), matching UIkit.
    padding: 7.8px 30px;
    line-height: 1.4;

    text-transform: $button-text-transform;
    transition: 0.1s ease-in-out;
    transition-property: color, background-color, border-color;

    // &:before {
    //     content: '\F101';
    //     position: absolute;
    //     left: 10px;
    //     top: 0;
    //     height: 100%;
    //     align-items: center;
    //     display: flex;
    //     font: normal normal normal 20px FontAwesome;
    //     text-rendering: auto;
    //     -webkit-font-smoothing: antialiased;
    // }

    // &-hide-arrow {
    //     padding-left: 20px;
    //     &:before {
    //         content: '';
    //     }
    // }
}

@mixin hook-button-hover() {
    &:not(:disabled):not(.uk-button-link):not(.uk-button-text) {
        // Workaround for SCSS: darken(var(--color), 5%)
        filter: brightness(84%);
    }
}

@mixin hook-button-active() {
    &:not(:disabled):not(.uk-button-link):not(.uk-button-text) {
        // Workaround for SCSS: darken(var(--color), 10%)
        filter: brightness(69%);
    }
}

@mixin hook-button-disabled() {
    border-color: $button-disabled-background;
    &:before {
        color: $button-disabled-color;
    }
}

@mixin hook-button-primary() {
    border: $button-border-width solid transparent;

    &:before {
        color: $global-secondary-background;
    }
}

@mixin hook-button-secondary() {
    border: $button-border-width solid transparent;

    &:before {
        color: $global-primary-background;
    }
}

@mixin hook-button-text() {
    text-transform: none;

    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        border-bottom: $button-text-border-width solid $button-text-border;
        transition: right 0.3s ease-out;
    }
}

@mixin hook-button-link() {
    text-transform: none;

    &:before {
        content: none;
    }
}

@mixin hook-inverse-button-default() {
    &:disabled {
        border-color: $button-disabled-background;
        background-color: $button-disabled-background;

        &:before {
            color: $button-disabled-color;
        }
    }
}

@mixin hook-inverse-button-default-hover() {
    &:disabled {
        background-color: $button-disabled-background;
    }
}

@mixin hook-inverse-button-primary() {
    &:disabled {
        background-color: $button-disabled-background;

        &:before {
            color: $button-disabled-color;
        }
    }
}

@mixin hook-inverse-button-primary-hover() {
    &:disabled {
        background-color: $button-disabled-background;
        color: $button-disabled-color;
    }
}

@mixin hook-inverse-button-secondary() {
    &:disabled {
        background-color: $button-disabled-background;
        color: $button-disabled-color;

        &:before {
            color: $button-disabled-color;
        }
    }
}

@mixin hook-inverse-button-secondary-hover() {
    &:disabled {
        background-color: $button-disabled-background;
        color: $button-disabled-color;
    }
}

@mixin hook-inverse-component-tab() {
    .uk-tab {
        @if (mixin-exists(hook-inverse-tab)) {
            @include hook-inverse-tab();
        }
    }

    .uk-tab > * > a {
        color: $inverse-tab-item-color;

        @if (mixin-exists(hook-inverse-tab-item)) {
            @include hook-inverse-tab-item();
        }
    }

    .uk-tab > * > a:hover,
    .uk-tab > * > a:focus {
        color: $inverse-tab-item-hover-color;
        @if (mixin-exists(hook-inverse-tab-item-hover)) {
            @include hook-inverse-tab-item-hover();
        }
    }

    .uk-tab > .uk-active > a {
        color: $inverse-tab-item-active-color;
        font-weight: bold;
        @if (mixin-exists(hook-inverse-tab-item-active)) {
            @include hook-inverse-tab-item-active();
        }
    }

    .uk-tab > .uk-disabled > a {
        color: $inverse-tab-item-disabled-color;
        @if (mixin-exists(hook-inverse-tab-item-disabled)) {
            @include hook-inverse-tab-item-disabled();
        }
    }
}

@mixin hook-navbar-nav-item() {
    text-transform: $navbar-nav-item-text-transform;
    transition: 0.1s ease-in-out;
    transition-property: color, background-color;
}

@mixin hook-navbar-nav-item-hover() {
    color: $shop-main;
}

@mixin hook-navbar-dropdown() {
    box-shadow: $navbar-dropdown-box-shadow;
    text-align: left;
    border-top: 5px solid #00adef;
}

@mixin hook-navbar-dropdown-nav() {
    font-size: $navbar-dropdown-nav-font-size;
}

@mixin hook-navbar-dropdown-nav-item() {
    position: relative;
    padding-left: 15px;
}

@mixin hook-pagination-item() {
    transition: color 0.1s ease-in-out;
    font-size: 1rem;
}

@mixin hook-inverse-component-text() {
    .uk-text-lead {
        color: $inverse-text-lead-color;
        @if (mixin-exists(hook-inverse-text-lead)) {
            @include hook-inverse-text-lead();
        }
    }

    .uk-text-meta {
        color: $inverse-text-meta-color;
        @if (mixin-exists(hook-inverse-text-meta)) {
            @include hook-inverse-text-meta();
        }
    }

    .uk-text-muted {
        color: $inverse-text-muted-color !important;
    }
    .uk-text-emphasis {
        color: $inverse-text-emphasis-color !important;
    }
    .uk-text-primary {
        color: $shop-main !important;
    }
    .uk-text-secondary {
        color: $inverse-text-secondary-color !important;
    }
    .uk-text-danger {
        color: white !important;
    }
}
