// Custom Properties
$shop-main: var(--shop-main) !default;
$shop-main--rgb: var(--shop-main--rgb) !default;
$shop-main--light: var(--shop-main--light) !default;
$shop-main--light-rgb: var(--shop-main--light-rgb) !default;
$shop-main--dark: var(--shop-main--dark) !default;
$shop-main--dark-rgb: var(--shop-main--dark-rgb) !default;
// TODO: validate default change
$shop-secondary: var(--shop-secondary, #3f494b) !default;
$shop-secondary--rgb: var(--shop-secondary--rgb) !default;
$shop-secondary--light: var(--shop-secondary--light) !default;
$shop-secondary--light-rgb: var(--shop-secondary--light-rgb) !default;
$shop-secondary--dark: var(--shop-secondary--dark) !default;
$shop-secondary--dark-rgb: var(--shop-secondary--dark-rgb) !default;

// Previously Custom Properties
$accent-main: #cc0099 !default;
$grey-main: #8c9596 !default;
$grey-dark-main: #132623 !default;
$grey-dark-secondary: #3f494b !default;
$grey-light-main: #e0e0e0 !default;
$grey-light-secondary: #f1f1f1 !default;
$font-main: black !default;


$base-body-color: #333 !default;

// UIkit Custom Properties
$global-primary-background: $shop-main !default;
$global-secondary-background: $shop-secondary !default;

// $alert-primary-background: lighten(mix(white, $global-primary-background, 40%), 20%) !default
$alert-primary-background: $global-primary-background !default;
$button-primary-background: $global-primary-background !default;
$button-secondary-background: $global-secondary-background !default;
//$button-primary-hover-background: darken($button-primary-background, 5%) !default;
$button-primary-hover-background: $button-primary-background !default;
$button-secondary-hover-background: $button-secondary-background !default;
//$button-primary-active-background: darken($button-primary-background, 10%) !default;
$button-primary-active-background: $button-primary-background !default;
$button-secondary-active-background: $button-secondary-background !default;

$global-inverse-color: #fff !default;

$button-primary-color: $global-inverse-color !default;

// Button
$button-disabled-background: $grey-main !default;
$button-text-disabled-color: $grey-main !default;
$button-link-disabled-color: $grey-main !default;
$button-link-hover-text-decoration: none !default;

$inverse-button-primary-color: $button-primary-color !default;
$inverse-button-primary-background: $button-primary-background !default;
$inverse-button-primary-hover-background: $button-primary-hover-background !default;
$inverse-button-primary-active-background: $button-primary-active-background !default;

$global-danger-background: $accent-main !default;

// Old UIKit overrides
$global-2xlarge-font-size: 2.25rem !default;
// TODO: validate, if this should be $shop-main
$global-color: #666 !default;
$global-font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
// TODO: who sets the body font size?
$global-font-size: 1rem !default;
$global-gutter: 30px !default;
$global-large-margin: 25px !default;
$global-link-color: $shop-main !default;
$global-link-hover-color: $shop-main !default;
$global-margin: 15px !default;
$global-medium-font-size: 1.25rem !default;
$global-medium-margin: 25px !default;
$global-muted-background: #f1f1f1 !default;
$global-muted-color: #f1f1f1 !default;
$global-small-gutter: 10px !default;
$global-xlarge-font-size: 1.375rem !default;
$button-font-size: $global-font-size !default;
$container-padding-horizontal: 15px !default;
$accordion-title-font-size: 1.429rem !default;
$inverse-global-color: rgba($global-inverse-color, 1) !default;
$inverse-global-muted-color: rgba($global-inverse-color, 1) !default;
$base-link-text-decoration: none !default;
$base-link-hover-text-decoration: none !default;
$base-ins-background: unset !default;
$base-ins-color: $global-secondary-background !default;
$base-heading-font-weight: 600 !default;
$breadcrumb-item-font-size: $global-font-size !default;
$breadcrumb-item-color: $grey-main !default;
$breadcrumb-item-active-color: $shop-main !default;
$breadcrumb-divider: '\f101' !default;
$breadcrumb-divider-margin-horizontal: 10px !default;
$breadcrumb-divider-color: $grey-main !default;
$button-padding-horizontal: $global-gutter * 0.667 !default;
$card-title-font-size: $global-medium-font-size !default;
$close-color: $global-color !default;
$container-padding-horizontal-s: $container-padding-horizontal !default;
$container-padding-horizontal-m: $container-padding-horizontal !default;
$form-disabled-background: $grey-main !default;
$form-disabled-color: $global-color !default;
$form-placeholder-color: $global-color !default;
$form-radio-checked-background: $shop-main !default;
$form-radio-checked-focus-background: $shop-main !default;
$form-icon-color: $global-color !default;
$internal-list-bullet-image: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20data-svg%3D%22chevron-double-right%22%3E%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%221.03%22%20points%3D%2210%206%2014%2010%2010%2014%22%3E%3C%2Fpolyline%3E%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23000%22%20stroke-width%3D%221.03%22%20points%3D%226%206%2010%2010%206%2014%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E' !default;
$navbar-nav-item-height: 70px !default;
$navbar-nav-item-font-size: $global-font-size !default;
$navbar-subtitle-font-size: $global-font-size !default;
$navbar-toggle-color: $font-main !default;
$navbar-dropdown-width: 300px !default;
$navbar-dropdown-margin: 0px !default;
$navbar-dropdown-padding: 20px !default;
$navbar-dropdown-background: $grey-light-main !default;
$navbar-dropdown-nav-item-color: $grey-dark-secondary !default;
$navbar-dropdown-nav-item-hover-color: $shop-main !default;
$navbar-nav-item-text-transform: none !default;
$navbar-background: white !default;
$navbar-nav-item-color: $font-main !default;
$navbar-nav-item-active-color: $shop-main !default;
$nav-default-font-size: $global-font-size !default;
$nav-default-item-color: $font-main !default;
$nav-default-item-hover-color: $shop-main !default;
$nav-default-item-active-color: $shop-main !default;
$nav-default-sublist-item-color: $font-main !default;
$nav-default-sublist-item-hover-color: $shop-main !default;
$nav-default-sublist-item-active-color: $shop-main !default;
$subnav-item-color: $global-color !default;
$subnav-item-text-transform: none !default;
$offcanvas-bar-color-mode: dark !default;
$offcanvas-bar-background: #ffffff !default;
$pagination-margin-horizontal: 20px !default;
$pagination-item-padding-vertical: 0 !default;
$pagination-item-padding-horizontal: 0 !default;
$pagination-item-color: $global-color !default;
$pagination-item-active-color: $shop-main !default;
$pagination-item-disabled-color: $grey-main !default;
$table-header-cell-color: $grey-dark-main !default;
$text-small-line-height: 1 !default;
$text-large-font-size: 18px !default;
$text-muted-color: $grey-main !default;
$text-primary-color: $shop-main !default;
$navbar-dropdown-nav-font-size: 0.875rem !default;
$tab-item-color: $grey-main !default;
$tab-item-font-size: $global-font-size !default;
$overlay-primary-background: rgba($shop-secondary--rgb, 0.7) !default;
$drop-margin: 0 !default;
$drop-width: 290px !default;
$navbar-dropdown-box-shadow: none !default;
$search-color: $global-color !default;
$search-placeholder-color: $global-color !default;
$search-icon-color: $global-color !default;

@import '~uikit/src/scss/variables-theme';
