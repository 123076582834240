.scheibenreparatur {
  .arten {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }
  
  h2 {
    font-size: 24px;
  }
  
  h3 {
    font-size: 1.35rem;
  }
  
  .box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }
  
  .box div {
    background-color: #cfd6dd;
    border: 1px solid #025494;
    padding: 20px 20px 20px 40px;
  }
  
  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  
  .div3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  
  .div4 {
    grid-area: 1 / 4 / 2 / 5;
  }
  
  .div5 {
    grid-area: 1 / 5 / 2 / 6;
  }
  
  .div6 {
    grid-area: 1 / 6 / 2 / 7;
  }
  
  .div7 {
    grid-area: 1 / 7 / 2 / 8;
  }
  
  .produkt-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0px;
    justify-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .produkt-box div {
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  }
  
  a.anchor {
    display: block;
    position: relative;
    top: -140px;
    visibility: hidden;
  }
  
  @media only screen and (max-width: 640px) {
    .arten {
      display: block;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 0px;
    }
  
    .div1 {
      grid-area: 1 / 1 / 2 / 2;
      margin-bottom: 20px;
    }
  
    .div2 {
      grid-area: 1 / 2 / 2 / 3;
      margin-bottom: 20px;
    }
  
    .div3 {
      grid-area: 1 / 3 / 2 / 4;
      margin-bottom: 20px;
    }
  
    .div4 {
      grid-area: 1 / 4 / 2 / 5;
      margin-bottom: 20px;
    }
  
    .div5 {
      grid-area: 1 / 5 / 2 / 6;
      margin-bottom: 20px;
    }
  
    .div6 {
      grid-area: 1 / 6 / 2 / 7;
      margin-bottom: 20px;
    }
  
    .div7 {
      grid-area: 1 / 7 / 2 / 8;
      margin-bottom: 20px;
    }
  
  }
}
