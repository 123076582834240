html {
    .shop_wrapper {
        max-width: 1200px;
        min-height: 100%;
        .paypal_cover {
            height: 200%;
            z-index: 1000;
        }
        .paypal_cover_text {
            z-index: 1000;
            text-align: center;
        }
        .checkout {
            @media (max-width: 540px) {
                .mobile-m-remove {
                    margin: 0;
                }
            }
            .checkout__form {
                margin-top: 15px;
            }
            .checkout_radiobutton {
                min-height: 30px;
                min-width: 30px;
            }
        }
        .invoice_form.checkoutDataForm {
            padding: 7px 15px;
        }
        .checkout_wrapper {
            .breadcrump_wrapper {
                background-color: white;
                width: 520px;
                margin: 0 auto;
                top: 170px;
                z-index: 9;

                @media (max-width: 900px) {
                    top: 90px;
                    width: 100%;
                    left: 0;
                }

                .link-to-previous {
                    padding-top: 8px;
                    @media (max-width: 600px) {
                        padding-left: 10px;
                    }

                    a {
                        color: $grey-dark-secondary;
                    }
                }
                .breadcrump_wrapper__heading {
                    margin: 0;
                    text-align: center;
                    padding: 20px 0 10px;
                    text-transform: uppercase;
                    font-weight: bold;
                    background: $grey-light-secondary;
                    font-size: 28px;
                    color: #0d1816;
                    @media (max-width: 900px) {
                        font-size: 22px;
                        padding: 15px 0 10px;
                    }
                }
                .progress_titleData {
                    opacity: 1;
                    font-weight: bold;
                    color: $shop-main;
                    position: relative;

                    .title_inner {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .breadcrump_num {
                            font-size: 22px;
                            font-weight: normal;
                            margin-right: 5px;
                        }

                        .breadcrump_text {
                            font-size: 16px;
                            font-weight: 300;
                            color: #454e4c;
                            @media (max-width: 540px) {
                                font-size: 13px;
                            }
                            @media (max-width: 350px) {
                                font-size: 10px;
                            }
                        }

                        &.active span {
                            //color: #cc0099;
                            color: white;
                        }
                    }
                    .title_inner.enabled {
                        color: $shop-main;
                        font-size: 20px;

                        i {
                            margin-left: 5px;
                        }
                    }
                }

                /* Global CSS, you probably don't need that */

                /* Breadcrumbs CSS */
                .arrow-steps {
                    margin-left: -3px;
                    margin-top: 5px;
                    margin-right: 15px;
                }
                .arrow-steps .step {
                    margin: 0 3px;
                    padding: 10px 10px 10px 30px;
                    background-color: $grey-light-secondary;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    -webkit-transition: background-color 0.2s ease;
                    transition: background-color 0.2s ease;
                    display: flex;
                    align-items: center;
                    max-height: 36px;
                    flex-grow: 1;
                    @media (max-width: 540px) {
                        padding: 10px 0 10px 30px;
                    }
                    &.enabled {
                        &.progressed {
                            .breadcrump_text {
                                color: $shop-main;
                            }
                            a span:before {
                                content: '\f00c';
                                position: absolute;
                                top: 3px;
                                left: -20px;
                                font: normal normal normal 16px/1 FontAwesome;
                                @media (max-width: 540px) {
                                    top: 1px;
                                    font-size: 14px;
                                    left: -15px;
                                }
                                @media (max-width: 350px) {
                                    top: 1px;
                                    font-size: 10px;
                                    left: -13px;
                                }
                            }
                        }
                    }
                    &.active {
                        background-color: $shop-main;
                        &:after {
                            border-left: 17px solid $shop-main;
                        }
                    }
                }

                .arrow-steps .step:after,
                .arrow-steps .step:before {
                    content: ' ';
                    position: absolute;
                    top: 0;
                    right: -17px;
                    width: 0;
                    height: 0;
                    //border-top: 26px solid transparent;
                    //border-bottom: 20px solid transparent;
                    //border-left: 17px solid $grey-light-main;

                    border-top: 19px solid transparent;
                    border-bottom: 17px solid transparent;
                    border-left: 17px solid $grey-light-secondary;

                    z-index: 2;
                    transition: border-color 0.2s ease;
                }

                .arrow-steps .step:before {
                    right: auto;
                    left: 0;
                    border-left: 17px solid #fff;
                    z-index: 0;
                }
                .arrow-steps .step:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }

                .arrow-steps .step span {
                    position: relative;
                }

                .arrow-steps .step.done span:before {
                    opacity: 1;
                    -webkit-transition: opacity 0.3s ease 0.5s;
                    -moz-transition: opacity 0.3s ease 0.5s;
                    -ms-transition: opacity 0.3s ease 0.5s;
                    transition: opacity 0.3s ease 0.5s;
                }

                .arrow-steps .step.current {
                    color: #fff;
                    background-color: #23468c;
                }

                .arrow-steps .step.current:after {
                    border-left: 17px solid #23468c;
                }

                .progress_clickedData {
                    opacity: 0.3;
                }
            }

            .location_image {
                flex: 1 250px;
            }

            .one_location {
                flex: 2 250px;
                margin-top: 5px;
                .adress_list_item {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .adress_list_item.aps {
                    background: $shop-main;
                    color: white;
                }

                .adress_list_car_make {
                    width: 25px;
                }
            }

            .firstLogin {
                .newCustomer {
                    display: none;
                    font-size: 25px;
                    border-bottom: black;
                    @media (max-width: 991px) {
                        display: inline;
                    }
                }

                .myBreadcrumb {
                    .text {
                        font-size: 25px;
                        border-bottom: black;
                        padding-left: 25px;
                    }

                    .hiddenText {
                        font-size: 25px;
                        border-bottom: black;
                        padding: 0;
                        @media (max-width: 991px) {
                            display: none;
                        }
                    }

                    .borderTop {
                        width: 100%;
                        height: 1px;
                        background-color: black;
                        margin: 0;
                    }
                }

                .registerWrapper {
                    background-color: $grey-dark-main;
                    position: absolute;
                    height: 100%;
                    right: 10px;
                    @media (max-width: 991px) {
                        position: relative;
                        margin-top: 60px;
                        padding-bottom: 50px;
                        margin-left: 20px;
                    }

                    a {
                        margin-right: 35px;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        padding: 5px 20px;
                        @media (max-width: 991px) {
                            width: 100%;
                            margin-right: 0;
                        }

                        button {
                            background: $shop-main;
                            border: none;
                            color: black;
                            cursor: pointer;
                        }
                    }
                }

                .form_container {
                    padding: 0;
                    margin: 0 10px 0 20px;

                    .form-signin {
                        .form-control {
                            width: 50%;
                        }

                        input:focus {
                            border-color: black;
                        }

                        a {
                            color: black;
                            margin-top: 10px;
                        }

                        button {
                            background: $shop-main;
                            border: none;
                            color: black;
                            float: right;
                            margin-right: 35px;
                            cursor: pointer;
                            padding: 5px 20px;
                            @media (max-width: 991px) {
                                width: 100%;
                                margin-right: 0;
                            }

                            .link {
                                color: black;
                            }
                        }
                    }
                }

                .borderBottom {
                    width: 100%;
                    height: 5px;
                    background-color: $shop-secondary;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .border {
                height: 5px;
                background-color: $shop-secondary;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }

        .checkout_button {
            font-size: 15px;
            padding: 8px 15px;
            color: white;
            border: none;
            min-width: 130px;
            transition: background-color 0.5s ease;
            background-color: $shop-main;

            .fa {
                color: $grey-dark-main;
                font-size: 17px;
            }
        }

        .back {
            .fa {
                padding-right: 5px;
            }

            &.last {
                @media (max-width: 320px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }

        .next {
            .fa {
                padding-right: 8px;
            }

            &.last {
                @media (max-width: 320px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }

        .next:hover {
            background: $shop-secondary;

            .fa {
                color: $grey-dark-main;
                transition: color 0.5s ease;
            }
        }

        @media (max-width: 768px) {
            span#cardexpiremonth.date1 {
                width: 100%;
            }
            span#cardexpiremonth.date1 iframe {
                width: 100%;
            }
            span#cardexpireyear.date2 {
                width: 100%;
            }
            span#cardexpireyear.date2 iframe {
                width: 100%;
            }
        }
    }
}
