.home {
    .map_headline {
        align-items: center;
        font-size: 20px;
    }
    h4 {
        font-weight: 900;
    }
    .teaser_image {
        @media (max-width: 900px) {
            max-width: 100%;
            position: relative;
        }
    }

    .top_left_alligned {
        @media (max-width: 900px) {
            display: flex;
            align-items: flex-start;
            position: relative;
        }
    }

    .teaser_image_container {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        padding-left: 15px;
        padding-top: 15px;

        .teaser_image_headline {
            @media (max-width: 900px) {
                margin-bottom: 0;
                display: flex;
                font-size: 24px;
                font-weight: 900;
                color: black;
                text-shadow: -0.5px 0 white, 0 0.5px white, 0.5px 0 white, 0 -0.5px white;
            }
        }
        .dangerously_slogan {
            display: flex;
        }
    }

    #teaser_tiles_container {
        max-width: 80%;
        margin: 0 auto;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        @media (max-width: 900px) {
            max-width: 100%;
            margin: 0 15px 0 15px;
        }

        .teaser_tile {
            width: 48%;
            background: $grey-dark-main;
            color: white;
            text-align: center;
            padding: 30px 5px;
            margin-top: -25px;
            z-index: 50;
            -webkit-box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.75);
            box-shadow: 0 7px 5px 0 rgba(0, 0, 0, 0.75);
            @media (max-width: 900px) {
                margin-top: 15px;
            }

            .text_big {
                font-size: 25px;
                @media (max-width: 768px) {
                    font-size: 16px;
                    font-weight: bold;
                    margin: 5px;
                }
                @media (max-width: 400px) {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .text_small {
                @media (max-width: 900px) {
                    font-size: 14px;
                    font-weight: 300;
                    text-align: center;
                    margin: 5px;
                }
                @media (max-width: 400px) {
                    font-size: 12px;
                }
            }

            .fa {
                &.fa-wrench,
                &.fa-shopping-cart {
                    font-size: 75px;
                    border: 35px solid $shop-main;
                    border-radius: 50%;
                    background: $shop-main;
                    margin-bottom: 5px;
                    min-width: 50px + 70px;
                    min-height: 50px + 70px;
                    @media (max-width: 900px) {
                        font-size: 55px;
                        border: 15px solid $shop-main;
                        margin-bottom: 15px;
                        min-width: 50px + 30px;
                        min-height: 50px + 30px;
                    }
                }
            }
        }
    }

    .header_title {
        @media (max-width: 900px) {
            color: $grey-dark-secondary;
            font-weight: bold;
            font-size: 18px;
            padding: 0 15px;
        }
    }

    .subtitle {
        @media (max-width: 900px) {
            color: $grey-dark-secondary;
            font-weight: 300;
            font-size: 15px;
            margin-top: -5px;
            margin-bottom: 15px;
            padding: 0 15px;
        }
    }

    .kachel_wrapper {
        padding: 0;
        margin-bottom: 50px;

        .kachel_background {
            padding: 25px 0;
            width: 500%;
            margin-left: -200%;
            background: rgb(241, 242, 243);
            @media (max-width: $breakpoint-medium-max) {
                width: auto;
                margin: 0;
                padding: 15px;
            }

            .kachelcontainer {
                margin: 0 auto;
                max-width: 1200px;
                position: relative;
                @media (max-width: $breakpoint-medium-max) {
                    width: auto;
                }

                .kachelcontainer_inner {
                    margin-left: -15px;

                    .kachel_meister {
                        position: absolute;
                        left: -150px;
                        padding: 0;
                        bottom: -25px;
                        width: 15%;
                        @media (max-width: 1400px) {
                            display: none;
                        }
                    }

                    .kachel {
                        float: left;
                        width: 25%;
                        padding-left: 15px;
                        @media (max-width: 540px) {
                            width: 50%;
                            margin-bottom: 15px;
                        }

                        .inner {
                            display: flex;
                            flex-direction: column;
                            background: white;
                            width: 100%;
                            box-shadow: 0 6px 18px 0 rgb(63, 73, 75);
                            -webkit-box-shadow: 0 6px 18px 0 rgb(63, 73, 75);
                            -moz-box-shadow: 0 6px 18px 0 rgb(63, 73, 75);
                            padding-top: 10px;

                            .cls-1 {
                                fill: $shop-main;
                            }
                            .cls-2 {
                                fill: white;
                            }

                            .kachel_title_container {
                                display: flex;
                                justify-content: center;
                                padding-bottom: 35px;
                                .kachel_title {
                                    font-size: 1.5em;
                                    color: $shop-main;
                                    align-self: center;
                                    @media (max-width: 900px) {
                                        font-size: 1em;
                                    }
                                }
                            }
                            .faq-fb-button {
                                border: none;
                                background: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    .anchor {
        display: block;
        position: relative;
        top: -230px;
        visibility: hidden;
        @media (max-width: 900px) {
            top: -140px;
        }
    }

    .title_service_station {
        margin: 0 10px;
    }
}

.no-scroll{
    overflow: hidden;
}
.no-pointer{
    pointer-events: none; 
}