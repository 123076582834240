@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
        url(https://techno-images.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Light.woff2) format('woff2');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
        url(https://techno-images.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Source Sans Pro Black'), local('SourceSansPro-Black'),
        url(https://techno-images.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Black.woff2) format('woff2');
}

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
        url(https://techno-images.s3.eu-central-1.amazonaws.com/fonts/SourceSansPro-Regular.woff2) format('woff2');
}
